<template>
  <v-card style="max-width: 1000px">
    <v-form v-model="valid" :disabled="loading" @submit.prevent="onSubmit">
      <v-card-text>
        <v-text-field
          v-model="lookupTypeData.description"
          class="mb-2"
          :label="$t('admin.assets.lookupTypes.field.description') + ' *'"
          :rules="[(v) => !!v || $t('validation.required')]"
          placeholder="Component - Property"
        />

        <v-autocomplete
          v-model="lookupTypeData.parent"
          :loading="lookupTypesPending"
          :items="lookupTypes"
          :label="$t('admin.assets.lookupTypes.field.parent')"
          item-title="description"
          item-value="_id"
          class="mb-2"
          clearable
        >
          <template #item="{ item, props: itemProps }">
            <v-list-item v-bind="itemProps">
              <template #title>{{ item.raw.description }}</template>
              <template #subtitle>
                <span v-if="item.raw.parent">
                  {{
                    `${$t("admin.assets.lookupTypes.field.parent")}: ${lookupTypes.find((type) => type._id === item.raw.parent)?.description}`
                  }}
                </span>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="lookupTypeData.organization"
          :items="customers"
          class="mb-2"
          :label="$t('admin.assets.lookupTypes.field.organization')"
          item-title="description"
          item-value="_id"
          clearable
        />

        <v-select
          v-model="lookupTypeData.domain"
          :label="$t('admin.domains.title') + ' *'"
          :items="[DomainType.CIVIL, DomainType.PUMPING_STATIONS]"
          :disabled="!!domainBySelectedOrganization || !!lookupType"
          :rules="[(v) => !!v || $t('validation.required')]"
        >
          <template #selection="{ item }">
            <domain-type-badge :type="item.value" />
          </template>
          <template #item="{ item, props: itemProps }">
            <v-list-item v-bind="itemProps" :title="undefined">
              <domain-type-badge :type="item.value" />
            </v-list-item>
          </template>
        </v-select>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" type="submit" :loading="loading">{{ $t("save") }}</v-btn>
        <v-dialog v-if="lookupType" width="600">
          <template #activator="{ props: dialogProps }">
            <v-btn v-bind="dialogProps" color="error">{{ $t("delete") }}</v-btn>
          </template>

          <template #default="{ isActive }">
            <lookup-type-delete-confirmation
              :lookup-type="lookupType"
              @cancel="
                () => {
                  isActive.value = false
                }
              "
              @submit="
                () => {
                  isActive.value = false
                  emit('delete')
                }
              "
            />
          </template>
        </v-dialog>
      </v-card-actions>
    </v-form>
  </v-card>

  <lookup-type-value-usage-card v-if="lookupType" class="mt-5" :lookup-type-id="lookupType._id" />
</template>

<script setup lang="ts">
import { DomainType } from "~~/stores/organization.store"

const emit = defineEmits<{
  (e: "submit", lookupValue: LookupType): void
  (e: "cancel" | "delete"): void
}>()

const props = defineProps<{
  lookupType?: LookupType | null
}>()
const { lookupType } = toRefs(props)

const $i18n = useI18n()

const lookupValueStore = useLookupValueStore()
const mainStore = useMainStore()
const organizationStore = useOrganizationStore()

const { data: lookupTypes, pending: lookupTypesPending } = useLazyAsyncData(
  "lookup-types",
  async () => {
    return (await lookupValueStore.getLookupTypesByPage({ pagination: false })).docs
  },
  { default: () => [] },
)

useLazyAsyncData("organizations", () => organizationStore.getAllOrganizations({ pagination: false }), {
  default: () => [] as Array<Organization>,
})
const { customers } = storeToRefs(organizationStore)

const lookupTypeData = ref<Partial<LookupType>>({
  description: lookupType?.value?.description,
  parent: lookupType?.value?.parent,
  organization: lookupType?.value?.organization,
  domain: lookupType?.value?.domain,
})

const valid = ref(false)
const loading = ref(false)

const domainBySelectedOrganization = computed(() => {
  const organization = customers.value.find((organization) => organization._id === lookupTypeData.value.organization)
  return organization?.domain
})

watch(
  () => lookupTypeData.value.organization,
  () => {
    if (domainBySelectedOrganization.value) {
      lookupTypeData.value.domain = domainBySelectedOrganization.value
    }
  },
)

const onSubmit = () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    if (!valid.value) {
      return
    }

    loading.value = true

    try {
      const response = lookupType?.value
        ? await lookupValueStore.updateLookupType(lookupType.value._id, lookupTypeData.value)
        : await lookupValueStore.createLookupType(lookupTypeData.value)
      emit("submit", response)
    } finally {
      loading.value = false
    }
  })
}

watch(
  () => lookupType?.value,
  () => {
    lookupTypeData.value = {
      description: lookupType?.value?.description,
      parent: lookupType?.value?.parent,
      organization: lookupType?.value?.organization,
      domain: lookupType?.value?.domain,
    }
  },
  { immediate: true },
)
</script>
